import React, { useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

import "./style.css";
import NavDropDown from "../NavDropdown/NavDropDown";
import LogBtn from "../LogBtn";

const Nav = () => {
  const [toggleMenu, setToggleMenu] = useState(true);
  const handleClick = () => {
    toggleMenu ? setToggleMenu(false) : setToggleMenu(true);
  };

  return (
    <div className="nav">
      <div className="navigation">
        <div className="frame">
          <button className="nav_burger" onClick={handleClick}>
            <MenuIcon />
          </button>
          <Link to="/">
            <img
              src="/images/logocabinet_compress.png"
              className="nav_logo"
              alt="logocabinet"
            ></img>
          </Link>
          <div
            className={`middle-nav-item ${toggleMenu ? "small" : "normal"} `}
          >
            <div className="option-wrapper">
              <Link to="/">
                <div className="option">Accueil</div>
              </Link>
            </div>
            <div className="option-wrapper">
              <Link to="/apropos">
                <div className="option">A propos</div>{" "}
              </Link>
            </div>
            <div className="option-wrapper">
              <NavDropDown />
            </div>
            <div className="option-wrapper">
              <Link to="/dashboard">
                <div className="option">Blog</div>
              </Link>
            </div>
            {/* <div className="option-wrapper">
              <Link to="/rdv">
                <div className="option">Rendez-vous</div>
              </Link>
            </div> */}

            <div className="option-wrapper">
              <Link to="/contact">
                <div className="option">Contact</div>
              </Link>
            </div>
          </div>
          <div className="btn">
            <LogBtn />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
